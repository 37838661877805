import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { Layout } from 'src/components/Layout';
import { Base } from 'src/components/layouts/Base';
import { Link } from 'src/components/links/Link';
import { SeasonQuery } from 'src/graphqlTypes';

interface SeasonProps {
    data: SeasonQuery;
}

const Season: FC<SeasonProps> = ({ data }) => {
    const season = data.contentfulSeason;
    if (!season) return null;

    return (
        <Layout>
            <Base spacing={2}>Season {season.seasonNumber}</Base>
            {data.contentfulSeason?.episodes?.map((episode) => (
                <div key={episode?.id}>
                    <Link to={`/season-${season.seasonNumber}/episode-${episode?.episodeNumber}`}>
                        Episode {episode?.episodeNumber}
                    </Link>
                </div>
            ))}
        </Layout>
    );
};

export const query = graphql`
    query Season($seasonNumber: Int!) {
        contentfulSeason(seasonNumber: { eq: $seasonNumber }) {
            id
            seasonNumber
            episodes {
                id
                episodeNumber
            }
        }
    }
`;

export default Season;
